define("discourse/plugins/discourse-interstitials/discourse/templates/components/fields/di-category-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field category-field">
    <div class="control-group">
      {{fields/di-field-label label=this.label field=this.field}}
  
      <div class="controls">
        {{category-selector
          categories=this.categories
          onChange=(action "setCategoryField")
          options=(hash maximum=this.field.maximum)
        }}
        {{fields/di-field-description description=this.description}}
      </div>
    </div>
  </section>
  */
  {
    "id": "86/ErJ4k",
    "block": "[[[10,\"section\"],[14,0,\"field category-field\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"label\",\"field\"],[[30,0,[\"label\"]],[30,0,[\"field\"]]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"categories\",\"onChange\",\"options\"],[[30,0,[\"categories\"]],[28,[37,2],[[30,0],\"setCategoryField\"],null],[28,[37,3],null,[[\"maximum\"],[[30,0,[\"field\",\"maximum\"]]]]]]]]],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"description\"],[[30,0,[\"description\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"fields/di-field-label\",\"category-selector\",\"action\",\"hash\",\"fields/di-field-description\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/components/fields/di-category-field.hbs",
    "isStrictMode": false
  });
});