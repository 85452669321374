define("discourse/plugins/discourse-interstitials/discourse/components/modal/interstitial-alert", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/cookie", "discourse/lib/url", "discourse-common/utils/decorators", "discourse/plugins/discourse-interstitials/discourse/lib/build-custom-styles", "@ember/template-factory"], function (_exports, _component, _object, _computed, _ajax, _ajaxError, _cookie, _url, _decorators, _buildCustomStyles, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="interstitial-alert-modal"
    @closeModal={{this.handleClose}}
    style={{html-safe this.customStyles}}
  >
    <:body>
      <h1 class="title">{{this.model.heading}}</h1>
  
      <img src={{this.model.image_url}} class="thumbnail" />
  
      {{#if this.isPreview}}
        <span class="interstitial-content">{{modal-content
            content=(add-target-to-markdown-links this.model.content)
          }}</span>
      {{else}}
        <span class="interstitial-content">{{modal-content
            content=this.model.content
          }}</span>
      {{/if}}
    </:body>
    <:footer>
      <div
        class={{concat
          "modal-footer footer-"
          (or this.model.cta_buttons_alignment "right")
        }}
      >
        {{#each this.primaryCtaButtons as |ctaButton|}}
          {{#if ctaButton.title}}
            {{cta-button button=ctaButton action=(action "goTo" ctaButton)}}
          {{/if}}
        {{/each}}
  
        {{#each this.snoozeCtaButtons as |ctaButton|}}
          {{#if ctaButton.title}}
            {{cta-button
              button=ctaButton
              action=(action "snoozeModal" ctaButton)
            }}
          {{/if}}
        {{/each}}
  
        {{#each this.dismissCtaButtons as |ctaButton|}}
          {{#if ctaButton.title}}
            {{cta-button
              button=ctaButton
              action=(action "cancelModal" ctaButton)
            }}
          {{/if}}
        {{/each}}
      </div>
    </:footer>
  </DModal>
  */
  {
    "id": "o7TzG56A",
    "block": "[[[8,[39,0],[[24,0,\"interstitial-alert-modal\"],[16,5,[28,[37,1],[[30,0,[\"customStyles\"]]],null]]],[[\"@closeModal\"],[[30,0,[\"handleClose\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"h1\"],[14,0,\"title\"],[12],[1,[30,0,[\"model\",\"heading\"]]],[13],[1,\"\\n\\n    \"],[10,\"img\"],[15,\"src\",[30,0,[\"model\",\"image_url\"]]],[14,0,\"thumbnail\"],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isPreview\"]],[[[1,\"      \"],[10,1],[14,0,\"interstitial-content\"],[12],[1,[28,[35,3],null,[[\"content\"],[[28,[37,4],[[30,0,[\"model\",\"content\"]]],null]]]]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"interstitial-content\"],[12],[1,[28,[35,3],null,[[\"content\"],[[30,0,[\"model\",\"content\"]]]]]],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[10,0],[15,0,[28,[37,5],[\"modal-footer footer-\",[28,[37,6],[[30,0,[\"model\",\"cta_buttons_alignment\"]],\"right\"],null]],null]],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"primaryCtaButtons\"]]],null]],null],null,[[[41,[30,1,[\"title\"]],[[[1,\"          \"],[1,[28,[35,9],null,[[\"button\",\"action\"],[[30,1],[28,[37,10],[[30,0],\"goTo\",[30,1]],null]]]]],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"snoozeCtaButtons\"]]],null]],null],null,[[[41,[30,2,[\"title\"]],[[[1,\"          \"],[1,[28,[35,9],null,[[\"button\",\"action\"],[[30,2],[28,[37,10],[[30,0],\"snoozeModal\",[30,2]],null]]]]],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"dismissCtaButtons\"]]],null]],null],null,[[[41,[30,3,[\"title\"]],[[[1,\"          \"],[1,[28,[35,9],null,[[\"button\",\"action\"],[[30,3],[28,[37,10],[[30,0],\"cancelModal\",[30,3]],null]]]]],[1,\"\\n\"]],[]],null]],[3]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"ctaButton\",\"ctaButton\",\"ctaButton\"],false,[\"d-modal\",\"html-safe\",\"if\",\"modal-content\",\"add-target-to-markdown-links\",\"concat\",\"or\",\"each\",\"-track-array\",\"cta-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/modal/interstitial-alert.hbs",
    "isStrictMode": false
  });
  const TIME_UNITS = {
    hours: "hours",
    minutes: "minutes"
  };
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    isPreview: false,
    init() {
      this._super(...arguments);
      if (this.model.basePath) {
        this.set("isPreview", false);
        this.registerImpression();
        if (!this.siteSettings.mark_as_seen_after_displaying) {
          return;
        }
        this.markAsSeen();
      } else {
        this.set("isPreview", true);
      }
    },
    handleClose(_ref) {
      let {
        initiatedBy
      } = _ref;
      if (this.isPreview) {
        this.closeModal();
        return;
      }
      let events = {
        initiatedByCloseButton: "discourse-interstitial:close_by_close_button",
        initiatedByClickOut: "discourse-interstitial:close_by_click_out",
        initiatedByESC: "discourse-interstitial:close_by_esc_key",
        initiatedByCtaButton: "discourse-interstitial:close_by_cancel_button"
      };
      let closedBy = initiatedBy || "initiatedByCtaButton";
      this.appEvents.trigger(events[closedBy], {
        interstitial_id: this.model.id
      });
      let allowedEventsToTrack = [events["initiatedByCloseButton"], events["initiatedByClickOut"], events["initiatedByESC"]];
      if (allowedEventsToTrack.includes(events[closedBy])) {
        this.trackCtaEvents(-1); // -1 indicates the modal was not closed by a cta button
      }
      if (this.siteSettings.mark_as_seen_after_displaying) {
        // Was already marked seen when it first opened
      } else if (!this.currentUser) {
        this.createSnoozeModalCookie(10, TIME_UNITS.minutes);
      } else {
        this.markAsSeen();
      }
      this.closeModal();
    },
    enabled: (0, _computed.and)("nameValid", "fileSelected"),
    disabled: (0, _computed.not)("enabled"),
    primaryCtaButtons(ctaButtons) {
      return ctaButtons.filter(b => b.link !== "snooze" && b.link !== "cancel");
    },
    snoozeCtaButtons(ctaButtons) {
      return ctaButtons.filter(b => b.link === "snooze");
    },
    dismissCtaButtons(ctaButtons) {
      return ctaButtons.filter(b => b.link === "cancel");
    },
    cancelModal(ctaButton) {
      this.trackCtaEvents(ctaButton.id);
      this.closeModal();
    },
    snoozeModal(ctaButton) {
      this.model.basePath = "/discourse-interstitial/my_interstitials";
      if (!this.isPreview) {
        this.trackCtaEvents(ctaButton.id);
        this.createSnoozeModalCookie();
        this.appEvents.trigger("discourse-interstitial:click_snooze_button", {
          interstitial_id: this.model.id
        });
      }
      this.closeModal();
    },
    goTo(ctaButton) {
      let link = ctaButton.link;
      if (this.isPreview) {
        window.open(link, "_blank");
      } else {
        let eventName = ctaButton.title.toLowerCase().replace(/\s+/g, "_");
        this.appEvents.trigger(`discourse-interstitial:${eventName}_cta_click`, {
          interstitial_id: this.model.id,
          link
        });
        if (!this.siteSettings.mark_as_seen_after_displaying) {
          if (!this.currentUser) {
            this.createSnoozeModalCookie(10, TIME_UNITS.minutes);
          } else {
            this.markAsSeen();
          }
        }
        if (this.siteSettings.enable_interstitial_analytics) {
          this.trackCtaEvents(ctaButton.id, () => this._visitLink(link));
        } else {
          this._visitLink(link);
        }
      }
    },
    _visitLink(link) {
      if (link.charAt(0) === "/") {
        _url.default.routeTo(link);
      } else {
        _url.default.redirectTo(link);
      }
    },
    trackCtaEvents(cta_buttons_id, callback) {
      if (!this.siteSettings.enable_interstitial_analytics) {
        return;
      }
      let impression = this.get("impression");
      if (!impression) {
        return;
      }
      (0, _ajax.ajax)(`/discourse-interstitial/impressions/${impression.id}/cta_click`, {
        type: "POST",
        data: {
          cta_click: {
            interstitial_id: this.model.id,
            discourse_interstitial_cta_buttons_id: cta_buttons_id
          }
        }
      }).then(() => {
        if (callback) {
          callback();
        }
      }).catch(e => this.set("error", (0, _ajaxError.extractError)(e)));
    },
    registerImpression() {
      if (!this.siteSettings.enable_interstitial_analytics) {
        return;
      }
      (0, _ajax.ajax)("/discourse-interstitial/impressions", {
        type: "POST",
        data: {
          impression: {
            discourse_interstitial_interstitial_id: this.model.id,
            ab_visible: true
          }
        }
      }).then(data => {
        this.set("impression", data);
      }).catch(e => this.set("error", (0, _ajaxError.extractError)(e)));
    },
    markAsSeen() {
      (0, _ajax.ajax)(`${this.model.basePath}/${this.model.id}`, {
        type: "PUT"
      }).then(() => {
        this.appEvents.trigger("discourse-interstitial:seen", {
          interstitial_id: this.model.id
        });
      }).catch(e => this.set("error", (0, _ajaxError.extractError)(e)));
    },
    createSnoozeModalCookie() {
      let quantity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 24;
      let timeUnit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : TIME_UNITS.hours;
      (0, _cookie.default)(`snoozed_interstitial_${this.model.id}`, moment().add(quantity, timeUnit));
    },
    get customStyles() {
      if (this.siteSettings.enable_custom_styling) {
        return (0, _buildCustomStyles.default)(this.model);
      }
    }
  }, [["method", "handleClose", [_object.action]], ["method", "primaryCtaButtons", [(0, _decorators.default)("model.cta_buttons")]], ["method", "snoozeCtaButtons", [(0, _decorators.default)("model.cta_buttons")]], ["method", "dismissCtaButtons", [(0, _decorators.default)("model.cta_buttons")]], ["method", "cancelModal", [_object.action]], ["method", "snoozeModal", [_object.action]], ["method", "goTo", [_object.action]]])));
});